html,
body {
  padding: 0;
  margin: 0;
  font-family: var(--font), sans-serif;
  color: #111634;
  min-width: 375px;
  font-size: 16px;
    background-color: var(--main-theme-back);
}

body.popup, body.slider, body.unscroll {
 overflow: hidden;
}

body.slider .popup {
    animation-name: none;
}

.popup #productImages {
    position: initial;
}

body.slider #productImages {
  z-index: 30;
}

a {
    color: var(--link-color);
    text-decoration: none;
}

* {
  box-sizing: border-box;
}

p {
    line-height: 2em;
    margin: 0 0 10px;
}

.container {
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
}

@media (max-width: 1350px) {
    .container {
        max-width: calc(100% - 50px);
    }
}

img {
  max-width: 100%;
  max-height: 100%;
}

h1 {
    margin: 0 0 24px;
    font-size: 24px;
    font-weight: 600;
}

h2 {
  margin: 0 0 22px;
  font-size: 22px;
  font-weight: 600;
}

h3 {
  margin: 0 0 20px;
  font-size: 20px;
  font-weight: 600;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.info {
  color: #999;
  font-size: 14px;
}

select {
  padding: 12px 20px;
  border-radius: 4px;
  border: 1px solid #D8D8D8;
  font-size: 16px!important;
  appearance: none;
  background-position: right 20px center;
  background-repeat: no-repeat;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNiIgaGVpZ2h0PSI0IiB2aWV3Qm94PSIwIDAgNiA0IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik0zIDRMMCAwSDZMMyA0WiIgZmlsbD0iIzkzOTc5QiIvPjwvc3ZnPg==)
}

.track {
  top: 10px;
  height: 4px;
  width: 100%;
  border-radius: 10px;
  background-color: #d5d7e6;
}

.track-1 {
  background-color: var(--main-theme);
}

.getreview-widget {
    z-index: 2;
    position: relative;
}

body > jdiv {
    z-index: 2;
    position: relative;
}

.trackNew {
    top: 10px;
    height: 4px;
    width: 100%;
    border-radius: 10px;
    background-color: var(--main-theme);
}

.trackNew-1 {
    background-color: #d5d7e6;
}

@media only screen and (max-width: 768px) {
  body {
    font-size: 15px;
  }
}

.sale-paysystem-yandex-button-item {
    background: #1247a5;
    color: #fff;
    border: 1px solid #1247a5;
    padding: 20px;
    margin: 20px;
    display: block;
    border-radius: 5px;
}

@media only screen and (max-width: 600px) {
  .container {
    max-width: calc(100% - 20px);
  }

  body {
    font-size: 14px;
  }

    h1 {
      margin: 0 0 15px;
      font-size: 22px;
  }
}

#react-select-3-placeholder {
    font-size: 15px;
}


.swiper-button-disabled {
    display: none!important;
}

.swiper-button-next,
.swiper-button-prev {
    color: #fff!important;
}


html,
body {
    padding: 0;
    margin: 0;
}


.text {
    line-height: 2em;
    font-size: 16px;

    iframe {
        max-width: 100%;
    }

    h1 {
        margin: 12px 0 22px;
        font-size: 22px!important
    }

    h2 {
        margin: 10px 0 20px;
        font-size: 20px!important
    }

    h3 {
        margin: 9px 0 18px;
        font-size: 18px!important
    }

    ul {
        list-style: disc;
        padding: 0 15px;
        margin: 10px 0 20px;
    }

    img {
        border-radius: 8px;
    }



    .block-two-columns {
        display: flex;
        gap: 20px;
        margin: 15px 0;

        img {
            max-width: 100%;
        }
    }

    .block-mark-list {
        margin: 15px 0 15px;
        padding: 5px 0 5px 34px;
        display: flex;
        flex-direction: column;
        grid-gap: 13px;
        gap: 13px;
        color: #0F1323;
        list-style: none;
        > li {
            position: relative;
            &:before {
                top: 4px;
                content: '•';
                position: absolute;
                color: #1247A5;
                left: -32px;
                font-size: 28px;
                line-height: 22px;
            }
        }
    }

    .block-num-list {
        margin: 15px 0;
        padding: 5px 0 5px 34px;
        display: flex;
        flex-direction: column;
        grid-gap: 13px;
        gap: 13px;
        color: #0F1323;
        list-style: none;
        > li {
            position: relative;
            &:before {
                content: counter(item, decimal-leading-zero) " ";
                position: absolute;
                color: #1247A5;
                left: -32px;
                top: 4px;
                font-weight: bold;
                line-height: 22px;
            }
            counter-increment: item;
        }
    }


    .block-adv-1 {
        margin: 15px 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        grid-gap: 13px;
        gap: 8px;
        color: #0F1323;
        list-style: none;
        > li {
            position: relative;
            padding: 25px 33px;
            background-color: #EEF0FC;
            border-radius: 8px;
            display: flex;
            align-items: center;
            &:before {
                content: counter(item, decimal-leading-zero) " ";
                color: #0F1323;
                font-size: 32px;
                top: 4px;
                margin-right: 18px;
                font-weight: bold;
                line-height: 22px;
            }
            counter-increment: item;
        }
    }


    .block-adv-2 {
        margin: 15px 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        grid-gap: 13px;
        gap: 8px;
        color: #fff;
        list-style: none;
        > li {
            position: relative;
            padding: 25px 33px;
            background: var(--gradient_about, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(105deg, #093686 0%, #1950B0 21.69%, #265FC3 59.77%, #0B3F9B 92.55%));
            border-radius: 8px;
            display: flex;
            align-items: center;
            &:before {
                content: counter(item, decimal-leading-zero) " ";
                color: #fff;
                font-size: 32px;
                top: 2px;
                margin-right: 18px;
                font-weight: bold;
                line-height: 22px;
            }
            counter-increment: item;
        }
    }

    .block-attention {
        margin: 15px 0;
        background-color: #EEF0FC;
        border-radius: 8px;
        padding: 25px 50px 25px 32px;
        position: relative;
        > div {
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 3px;
        }

        &:after {
            content: '';
            background-color: #FFE3E3;
            position: absolute;
            right: 0;
            height: 100%;
            width: 40px;
            top: 0;
            border-radius: 0 8px 8px 0;
            background-repeat: no-repeat;
            background-position: 50%;
            background-image: url("data:image/svg+xml,%0A%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.99 20C4.46846 19.9945 -0.00367557 15.5149 2.26684e-06 9.99334C0.00368465 4.47179 4.48179 -0.00183754 10.0033 5.66223e-07C15.5249 0.00183981 20 4.47845 20 10C19.9967 15.5254 15.5154 20.0022 9.99 20ZM2 10.172C2.04732 14.5732 5.64111 18.1095 10.0425 18.086C14.444 18.0622 17.9995 14.4875 17.9995 10.086C17.9995 5.6845 14.444 2.10977 10.0425 2.08599C5.64111 2.06245 2.04732 5.59876 2 10V10.172ZM11 15H9V13H11V15ZM11 11H9V5H11V11Z' fill='%23FF4E52'/%3E%3C/svg%3E%0A");
        }
    }

    .block-frame {
        margin: 15px 0;
        border: 2px solid #D0DAED;
        border-radius: 8px;
        padding: 30px 60px;
        text-align: center;
        color: #1247A5;
    }

    .block-pros-cons {
        margin: 15px 0;
        display: flex;
        gap: 10px;

        > div {
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            padding: 25px;
            align-items: center;
            text-align: center;
            gap: 10px;
            font-size: 16px;
            color: #0F1323;

            &:before {
                content: '';
                width: 21px;
                height: 20px;
            }
            &:first-child {
                background-color: #DEEFDC;

                &:before {
                    background-image: url("data:image/svg+xml,%0A%3Csvg width='21' height='20' viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.5 20C4.97715 20 0.5 15.5228 0.5 10C0.5 4.47715 4.97715 0 10.5 0C16.0228 0 20.5 4.47715 20.5 10C20.4939 15.5203 16.0203 19.9939 10.5 20ZM2.5 10.172C2.54732 14.5732 6.14111 18.1095 10.5425 18.086C14.944 18.0622 18.4995 14.4875 18.4995 10.086C18.4995 5.68451 14.944 2.10977 10.5425 2.086C6.14111 2.06246 2.54732 5.59876 2.5 10V10.172ZM11.5 15H9.5V11H5.5V9H9.5V5H11.5V9H15.5V11H11.5V15Z' fill='%23229317'/%3E%3C/svg%3E%0A");
                }
            }

            &:last-child {
                background-color: #FFE3E3;

                &:before {
                    background-image: url("data:image/svg+xml,%0A%3Csvg width='21' height='20' viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.5 20C4.97715 20 0.5 15.5228 0.5 10C0.5 4.47715 4.97715 0 10.5 0C16.0228 0 20.5 4.47715 20.5 10C20.4939 15.5203 16.0203 19.9939 10.5 20ZM2.5 10.172C2.54732 14.5732 6.14111 18.1095 10.5425 18.086C14.944 18.0622 18.4995 14.4875 18.4995 10.086C18.4995 5.68451 14.944 2.10977 10.5425 2.086C6.14111 2.06246 2.54732 5.59876 2.5 10V10.172ZM15.5 11H5.5V9H15.5V11Z' fill='%23FF4E52'/%3E%3C/svg%3E%0A");
                }
            }
        }
    }

    .block-attach {
        margin: 15px 0;
        background-color: #EEF0FC;
        border-radius: 8px;
        padding: 27px 50px 27px 32px;
        line-height: 25px;
        position: relative;
        color: #fff;
        background: var(--gradient_about, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(105deg, #093686 0%, #1950B0 21.69%, #265FC3 59.77%, #0B3F9B 92.55%));

        &:before {
            content: '';
            width: 20px;
            height: 24px;
            position: absolute;
            right: 37px;
            top: 40px;
            background-image: url("data:image/svg+xml,%0A%3Csvg width='20' height='24' viewBox='0 0 20 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.2983 0.0175781C12.825 0.0175781 11.3531 0.601268 10.2495 1.76563L2.24169 10.2129C-0.752439 13.3725 -0.752439 18.4517 2.24169 21.6113C2.33399 21.7086 2.44492 21.7863 2.56786 21.8398C2.56981 21.8405 2.57176 21.8412 2.57372 21.8418C5.59036 24.7004 10.2958 24.6685 13.1929 21.6113L18.8257 15.668C19.0853 15.4113 19.1845 15.0335 19.0843 14.6824C18.9842 14.3313 18.7007 14.0627 18.3447 13.9816C17.9887 13.9005 17.6168 14.0199 17.3745 14.293L11.7417 20.2363C9.48991 22.6126 5.94659 22.6126 3.69481 20.2363C1.44294 17.86 1.44294 13.9643 3.69481 11.5879L11.7007 3.14063C13.1654 1.59534 15.4332 1.59534 16.8979 3.14063C18.3625 4.68577 18.3625 7.23611 16.8979 8.78125L9.61083 16.4707C8.93193 17.1865 7.94093 17.1859 7.26317 16.4707C6.58541 15.7555 6.58541 14.5512 7.26317 13.8359L13.1245 7.65038C13.3841 7.3937 13.4833 7.01594 13.3831 6.66485C13.283 6.31375 12.9995 6.04513 12.6435 5.96404C12.2875 5.88296 11.9156 6.0023 11.6733 6.27539L5.81005 12.4609C4.38981 13.9597 4.38981 16.3469 5.81005 17.8457C5.90485 17.9455 6.01921 18.0246 6.14599 18.0781C7.58663 19.2816 9.73871 19.2408 11.062 17.8457L18.3491 10.1563C20.4655 7.92347 20.4959 4.42922 18.5542 2.08985C18.5074 1.96956 18.4378 1.85946 18.3491 1.76563C17.2455 0.601278 15.7716 0.0175781 14.2983 0.0175781Z' fill='white'/%3E%3C/svg%3E%0A");
        }
    }

    .block-check-list {
        margin: 15px 0 15px 5px;
        padding: 5px 0 5px 36px;
        display: flex;
        flex-direction: column;
        grid-gap: 13px;
        gap: 13px;
        color: #0F1323;
        list-style: none;
        > li {
            position: relative;
            &:before {
                content: '';
                background-image: url("data:image/svg+xml,%0A%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C19.9939 15.5203 15.5203 19.9939 10 20ZM9.984 18H10C14.4167 17.9956 17.9942 14.4127 17.992 9.996C17.9898 5.57929 14.4087 2 9.992 2C5.57528 2 1.99421 5.57929 1.992 9.996C1.98979 14.4127 5.56729 17.9956 9.984 18ZM8 15L4 11L5.41 9.59L8 12.17L14.59 5.58L16 7L8 15Z' fill='%23229317'/%3E%3C/svg%3E%0A");
                position: absolute;
                left: -32px;
                top: 5px;
                font-size: 28px;
                width: 20px;
                height: 20px;
                line-height: 22px;
            }
        }
    }


    .block-list-pros-cons {
        margin: 15px 0 15px 5px;
        padding: 5px 0 5px 36px;
        display: flex;
        flex-direction: column;
        grid-gap: 13px;
        gap: 13px;
        color: #0F1323;
        list-style: none;
        > li {
            position: relative;
            &:before {
                content: '';
                position: absolute;
                left: -32px;
                top: 5px;
                font-size: 28px;
                width: 20px;
                height: 20px;
                line-height: 22px;
            }
            &:first-child {
                &:before {
                    background-image: url("data:image/svg+xml,%0A%3Csvg width='22' height='20' viewBox='0 0 22 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.1699 0.613281C12.7876 0.615031 12.4048 0.762691 12.1133 1.05469L6.58398 6.59571C6.20998 6.97071 6 7.47782 6 8.00782V18C6 19.105 6.895 20 8 20H16.9922C17.7912 20 18.5141 19.5241 18.8301 18.7891L21.8379 11.7871C21.9449 11.5381 22 11.2691 22 10.9981V9.00001C22 7.89501 21.105 7.00001 20 7.00001H13.6484L14.6426 2.43556C14.7526 1.93156 14.5955 1.40594 14.2285 1.04494C13.9345 0.755941 13.5523 0.611531 13.1699 0.613281ZM1 8C0.448 8 0 8.448 0 9V19C0 19.552 0.448 20 1 20H3C3.552 20 4 19.552 4 19V9C4 8.448 3.552 8 3 8H1Z' fill='%23229317'/%3E%3C/svg%3E%0A");
                }
            }
            &:last-child {
                color: #FF4E52;
                &:before {
                    background-image: url("data:image/svg+xml,%0A%3Csvg width='22' height='20' viewBox='0 0 22 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.1699 20C12.7876 19.9982 12.4048 19.8506 12.1133 19.5586L6.58398 14.0176C6.20998 13.6426 6 13.1355 6 12.6055L6 2.61327C6 1.50826 6.895 0.613265 8 0.613265L16.9922 0.613265C17.7912 0.613264 18.5141 1.0892 18.8301 1.8242L21.8379 8.82616C21.9449 9.07516 22 9.34422 22 9.61522L22 11.6133C22 12.7183 21.105 13.6133 20 13.6133L13.6484 13.6133L14.6426 18.1777C14.7526 18.6817 14.5955 19.2073 14.2285 19.5683C13.9345 19.8573 13.5523 20.0017 13.1699 20ZM1 12.6133C0.447999 12.6133 -6.84933e-07 12.1653 -7.3319e-07 11.6133L-1.60742e-06 1.61328C-1.65568e-06 1.06128 0.447998 0.613275 0.999999 0.613275L3 0.613275C3.552 0.613275 4 1.06128 4 1.61328L4 11.6133C4 12.1653 3.552 12.6133 3 12.6133L1 12.6133Z' fill='%23FF4E52'/%3E%3C/svg%3E%0A");
                }
            }
        }
    }
}
